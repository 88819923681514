import React, { useEffect, useState } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import { getDetailOrders } from '../../store/ordersSlice';
import moment from 'moment'

function Vouchers() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { vouchers } = useSelector((state) => state.vouchers)

    const columns = [
        // {
        //   dataField: "_id",
        //   text: "ID",
        // },
        {
          dataField: "brandOrderId",
          text: "Order Id",
        },
        {
          dataField: "cardNumber",
          text: "Card Number"
        },
        {
          dataField: "cardPin",
          text: "Voucher"
        },
        {
            dataField: "amount",
            text: "Amount"
          },
        {
            dataField: "resellerMobile",
            text: "Reseller"
        },
        {
            dataField: "product",
            text: "Product"
        },
        {
            dataField: "orderedByMobile",
            text: "Admin"
        },
        {
            dataField: "createdAt",
            text: 'Date Time',
            formatter: (cell, row, rowIndex) => {
              return (
                moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')
              )
            }
        },
        {
          dataField: "expiredBy",
          text: "Expired Date"
        },
        {
          dataField: "activationCode",
          text: "Activation Code"
        },
        {
          dataField: "activationUrl",
          text: "Activation Url"
        },
      ];
      
    const getVouchersData = async () => {
        await dispatch(getDetailOrders())
    }
    useEffect(() => {
        getVouchersData()
    }, [])
    
  return (
    <div className='container'>
        <div className='mb-2' style={{display: 'flex', justifyContent: 'space-between'}}>
            <div className=''>
                <Button onClick={() => navigate('/vouchers/add')}> Generate Vouchers </Button>
            </div>
            {/* <div className=''>
                <Button onClick={() => navigate('/vouchers/orders')}> Orders  </Button>
            </div> */}
        </div>
        <div className='row'>
          <Card style={{ padding: '10px' }}>
            <Card.Title>Vouchers</Card.Title>
            <Card.Body>
                {
                    (vouchers && vouchers.length) ?
                    <BootstrapTable
                        bootstrap4
                        keyField="id"
                        data={vouchers}
                        columns={columns}
                        pagination={paginationFactory({ sizePerPage: 10, hideSizePerPage: true, onPageChange:(page)=>console.log("DB CALL with page" + page) })}
                    
                    />
                    : null
                }
              
            </Card.Body>
          </Card>
        </div>
    </div>
  );
}

export default Vouchers;
