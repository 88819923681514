import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import resellerService from '../services/resellerService';
import { handleAuthApiCall } from '../utils/apiUtils';

export const addReseller = createAsyncThunk('reseller/addReseller', async (reseller, thunkAPI) => {
  return handleAuthApiCall(resellerService.addReseller, reseller, thunkAPI);
});

export const getResellers = createAsyncThunk('resellers/getResellers', async (thunkAPI) => {
  return handleAuthApiCall(resellerService.getResellers, {}, thunkAPI);
});

const resellersSlice = createSlice({
  name: 'resellers',
  initialState: {
    loading: false,
    resellers: [],
    error: null
  },
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(addReseller.pending, (state) => {
        state.loading = true;
      })
      .addCase(addReseller.fulfilled, (state, action) => {
        if(!action.payload.success) {
          state.error = action.payload.message
        }
        state.loading = false;
      })
      .addCase(addReseller.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getResellers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getResellers.fulfilled, (state, action) => {
        if(action.payload.success) {
            state.resellers = action.payload.data.resellers
        } else {
          state.error = action.payload.message
        }
        state.loading = false;
      })
      .addCase(getResellers.rejected, (state, action) => {
        state.loading = false;
      })
  },
});

export const {  } = resellersSlice.actions;

export default resellersSlice.reducer;
