import React from 'react';
// import logo from './logo.svg';
// import { Counter } from './features/counter/Counter';
// import './App.css';
import Layout from '../components/Layout/Layout';
// import Hero from '../../features/herosection/hero';
// import VBCards from '../../features/vbcards/VBCards';
// import Clients from '../../features/clients/Clients';
// import Contact from '../../features/contact/Contact';
// import About from '../../features/about/About';

function Home() {
  return (
    <Layout>
        <div className='container'>
          <div className='row'>
            <div class="col-xxl-4 col-md-6">
                <div class="card info-card sales-card">
                  <div class="card-body">
                    <h5 class="card-title">Sales</h5>

                    <div class="d-flex align-items-center">
                      <div class="ps-3">
                        <h6>145</h6>
                        <span class="text-success small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">increase</span>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
          </div>
        </div>
    </Layout>
    
  );
}

export default Home;
