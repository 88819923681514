import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import orderService from '../services/orderService';
import { handleAuthApiCall } from '../utils/apiUtils';

export const addOrder = createAsyncThunk('order/addOrder', async (order, thunkAPI) => {
  return handleAuthApiCall(orderService.addOrder, order, thunkAPI);
});

export const getOrders = createAsyncThunk('orders/getOrders', async (thunkAPI) => {
  return handleAuthApiCall(orderService.getOrders, {}, thunkAPI);
});

export const getDetailOrders = createAsyncThunk('orders/getDetailOrders', async (thunkAPI) => {
    return handleAuthApiCall(orderService.getDetailOrders, {}, thunkAPI);
  });

export const getProducts = createAsyncThunk('brandauth/getProducts', async (thunkAPI) => {
  return handleAuthApiCall(orderService.getProducts, {}, thunkAPI);
});

const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    loading: false,
    orders: [],
    vouchers: [],
    products: [],
    error: null
  },
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(addOrder.fulfilled, (state, action) => {
        if(!action.payload.success) {
          state.error = action.payload.message
        }
        state.loading = false;
      })
      .addCase(addOrder.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        if(action.payload.success) {
            state.orders = action.payload.data.orders
        } else {
          state.error = action.payload.message
        }
        state.loading = false;
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getDetailOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDetailOrders.fulfilled, (state, action) => {
        if(action.payload.success) {
            state.vouchers = action.payload.data.orders
        } else {
          state.error = action.payload.message
        }
        state.loading = false;
      })
      .addCase(getDetailOrders.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        if(action.payload.success) {
            state.products = action.payload.data
        } else {
          state.error = action.payload.message
        }
        state.loading = false;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.loading = false;
      })
  },
});

export const {  } = ordersSlice.actions;

export default ordersSlice.reducer;
