import apiRequest from '../utils/api';
import { SEND_OTP, VERIFY_OTP } from '../utils/apiRoutes'

const register = async (userData) => {
  return await apiRequest('register', 'POST', userData);
};

const login = async (userData) => {
  return await apiRequest('login', 'POST', userData);
};

const sendOtp = async (userData) => {
  return await apiRequest(SEND_OTP, 'POST', userData);
};

const verifyOtp = async (userData) => {
  return await apiRequest(VERIFY_OTP, 'POST', userData);
};

const getUserData = async (token) => {
  return await apiRequest('user', 'GET', null, token);
};

const authService = {
  register,
  login,
  getUserData,
  sendOtp,
  verifyOtp
};

export default authService;
