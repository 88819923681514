import React from "react";
import { useNavigate } from "react-router-dom";

export default function Sidebar() {
  const navigate = useNavigate();
  const navigateToRoute = (routeName) => {
    console.log("welcome to navigation")

    navigate('/'+routeName)
  }
  return (
    <aside id="sidebar" className="sidebar">

    <ul className="sidebar-nav" id="sidebar-nav">

      <li className="nav-item" onClick={() => navigateToRoute('')}>
        <a className="nav-link ">
          <i className="bi bi-grid"></i>
          <span>Dashboard</span>
        </a>
      </li>
      <li className="nav-item" onClick={() => navigateToRoute('vouchers')}>
        <a className="nav-link collapsed" >
          <i className="bi bi-person"></i>
          <span>Vouchers</span>
        </a>
      </li>
      <li className="nav-item"  onClick={() => navigateToRoute('customers')}>
        <a className="nav-link collapsed" >
          <i className="bi bi-person"></i>
          <span>Customers</span>
        </a>
      </li>

      <li className="nav-item"  onClick={() => navigateToRoute('reports')}>
        <a className="nav-link collapsed">
          <i className="bi bi-question-circle"></i>
          <span>Reports</span>
        </a>
      </li>
    </ul>

  </aside>

  
  );
}