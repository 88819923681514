import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getResellers } from '../../store/resellersSlice';
import { addOrder, getProducts } from '../../store/ordersSlice';

function AddVouchers() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [validated, setValidated] = useState(false);
    const [brand, setBrand] = useState('amazon');
    const [noVouchers, setNoVouchers] = useState(1);
    const [voucherValue, setVoucherValue] = useState();
    const [reseller, setReseller] = useState(null);
    const [product, setProduct] = useState(null);
    const [denominations, setDenominations] = useState([]);
    const [minAmount, setMinAmount] = useState(0);
    const [maxAmount, setMaxAmount] = useState(0);
    const [voucherAmountPlaceholder, setVoucherAmountPlaceholder] = useState('');
    const error = false;
    const { resellers } = useSelector((state) => state.resellers)
    const { products } = useSelector((state) => state.vouchers)
    
    const getResellerData = async () => {
        await dispatch(getProducts())
        await dispatch(getResellers())
    }
    useEffect(() => {
      getResellerData()
    }, [])

    useEffect(() => {
        if(product == "DISABLEDSTS") {
            setMinAmount(1)
            setMaxAmount(10000)
            setVoucherAmountPlaceholder('Please Enter between 1 - 10000')
            setDenominations([])
        } else if(product) {
            let productData = products.filter(p => p.sku == product)[0];
            console.log("productData")
            console.log(productData)
            setMinAmount(productData.minAmount)
            setMaxAmount(productData.maxAmount)
            if(productData && productData.isDenominations) {
                setDenominations(productData.denominations)
                console.log("welcome ....")
            } else {
                setDenominations([])
            }
            setVoucherAmountPlaceholder((productData.denominations && productData.denominations.length) ? 'Please Choose Below Denomination' : 'Please Enter between ' + productData.minAmount + ' - '+ productData.maxAmount)
        }
        setVoucherValue()
       
    }, [product])

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {
            event.preventDefault();
            let reqObj = {
                reseller,
                noVouchers,
                voucherValue,
                brand: product
            }
            console.log(reqObj)
            let orderData = await dispatch(addOrder(reqObj));
            console.log("orderData.payload")
            console.log(orderData)
            if(orderData.payload.success) {
                navigate('/vouchers')
            } else {
                toast.error(orderData.payload.message)
            }
            
        }
        setValidated(true);
        
    };
  return (
    <div className='container'>
        <div className='row mb-3'>
            <div className='col-sm-12'>
                <div className='brand-list-content'>

                    {/* <div className='brand-list'>
                        <input type="radio" name="brand" className="btn-check" id="btn-amazon-outlined" value="amazon" autoComplete="off" checked={brand == 'amazon'} onChange={(e) => {setBrand(e.target.value)}} />
                        <label className="btn btn-outline-primary" htmlFor="btn-amazon-outlined">Amazon</label>
                    </div>
                    <div className='brand-list'>
                        <input type="radio" name="brand" className="btn-check" id="btn-amazon-sv-outlined" value="amzon-sv" autoComplete="off" checked={brand == 'amazon-sv'} onChange={(e) => {setBrand(e.target.value)}} />
                        <label className="btn btn-outline-primary" htmlFor="btn-amazon-sv-outlined">Amazon SV</label>
                    </div> */}
                    {/* <div className='brand-list'>
                        <input type="radio" name="brand" className="btn-check" id="btn-flipkart-outlined" value="flipkart" autoComplete="off" checked={brand == 'flipkart'} onChange={(e) => {setBrand(e.target.value)}} />
                        <label className="btn btn-outline-primary" htmlFor="btn-flipkart-outlined">Flipkart</label>
                    </div> */}
                </div>
            </div>
        </div>
        <div className='row mx-5'>
            <Card>
                <Card.Title>Generate Vouchers</Card.Title>
                <Card.Body>
                    {
                        error ? 
                        <Row>
                            <p>Problem in generating Vouchers!</p>
                        </Row>
                        : null
                    }
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        {
                            (products && products.length) ?
                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                <Col sm={3}>
                                    <Form.Label> Product </Form.Label>
                                </Col>
                                <Col sm={6}>
                                    <Form.Select defaultValue={product} value={product} onChange={(e) => setProduct(e.target.value)} required >
                                        <option value="">Select Product</option>
                                        {products.map((productData) => {
                                            return (<option key={productData.sku} value={productData.sku}>{productData.productName}</option>)
                                        })}
                                        <option key={"DISABLEDSTS"} value={"DISABLEDSTS"}>{"DISABLEDSTS"}</option>
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                            : null
                        }
                        {
                            (resellers && resellers.length) ?
                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                <Col sm={3}>
                                    <Form.Label> Customer </Form.Label>
                                </Col>
                                <Col sm={6}>
                                    <Form.Select defaultValue={reseller} value={reseller} onChange={(e) => setReseller(e.target.value)} required >
                                        <option value="">Select Customer</option>
                                        {resellers.map((resellerData) => {
                                            return (<option key={resellerData._id} value={resellerData._id}>{resellerData.name}</option>)
                                        })}
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                            : null
                        }
                        {/* <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Col sm={3}>
                                <Form.Label> No. of Vouchers </Form.Label>
                            </Col>
                            <Col sm={6}>
                                <Form.Control 
                                    type="number" placeholder="10" required 
                                    onChange={(e) => setNoVouchers(e.target.value)}
                                    value={noVouchers}
                                />
                            </Col>
                        </Form.Group> */}
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Col sm={3}>
                            <Form.Label> Amount Of Voucher </Form.Label>
                            </Col>
                            <Col sm={6}>
                                <Form.Control
                                    required
                                    type="number"
                                    placeholder={voucherAmountPlaceholder}
                                    min={minAmount}
                                    max={maxAmount}
                                    onChange={(e) => setVoucherValue(e.target.value)}
                                    value={voucherValue}
                                    disabled={(denominations && denominations.length) ? true : false}
                                />
                                <p>(
                                {
                                    (denominations && denominations.length) ? 'Please Choose Below Denomination' : 'Please Enter between ' +minAmount + ' - '+ maxAmount 
                                }
                                )</p>
                            </Col>
                        </Form.Group>
                        <div className="row">
                            {
                                (denominations && denominations.length) ?
                                <div className="row denomination-list">
                                    {
                                        denominations.map((denominationData) => {
                                                return (
                                                    <div className='brand-list denomination-list-data' key={"k-"+denominationData}>
                                                        <input type="radio" name="dimention" className="btn-check" id={"btn-amazon-outlined-"+denominationData} value={denominationData} autoComplete="off" checked={voucherValue == denominationData} onChange={(e) => {console.log(" === "+e.target.value); setVoucherValue(e.target.value)}} />
                                                        <label className="btn btn-outline-primary" htmlFor={"btn-amazon-outlined-"+denominationData}>{denominationData}</label>
                                                    </div>
                                                )
                                        })
                                    }
                                </div>
                                : null
                            }
                        </div>
                        <div className='row' style={{justifyContent: 'center', padding: '20px'}}>
                            <Button type="submit" style={{width: '150px'}}>Get Vouchers</Button>
                        </div>
                        
                    </Form>
                </Card.Body>
            </Card>
            
        </div>
        <ToastContainer />
    </div>
  );
}

export default AddVouchers;
