import logo from './logo.svg';
import {Routes, Route, Navigate} from 'react-router-dom'
import Home from './pages/Home';
import Login from './pages/Login';
import ProtectedRoute from './components/Layout/ProtectedRoute';
import './App.css';
import Otp from './pages/Otp';
import Layout from './components/Layout/Layout';
import Vouchers from './pages/Vouchers';
import AddVouchers from './pages/Vouchers/add';
import Resellers from './pages/Resellers';
import AddReseller from './pages/Resellers/add';
import Orders from './pages/Vouchers/orders';

function App() {
  return (
    <Layout>
      <Routes>
          <Route path="/"  element={<ProtectedRoute />} >
            <Route path="/" element={<Home />} />
          </Route>
          <Route path="/vouchers"  element={<ProtectedRoute />} >
            <Route path="/vouchers" element={<Vouchers />} />
            <Route path="/vouchers/add" element={<AddVouchers />} />
            {/* <Route path="/vouchers/orders" element={<Orders />} /> */}
          </Route>
          <Route path="/customers"  element={<ProtectedRoute />} >
            <Route path="/customers" element={<Resellers />} />
            <Route path="/customers/add" element={<AddReseller />} />
          </Route>
          <Route path="/login"  element={<Login />} />
          <Route path="/otp"  element={<Otp />} />
          <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Layout>
  );
}

export default App;
