
import apiRequest from '../utils/api';
import { ADD_RESELLER, GET_RESELLERS } from '../utils/apiRoutes'

const addReseller = async (reqObj, userToken) => {
    return await apiRequest(ADD_RESELLER, 'POST', reqObj, userToken, true);
  };

const getResellers = async (reqObj, userToken) => {
    return await apiRequest(GET_RESELLERS, 'POST', reqObj, userToken);
};

const usersService = {
    addReseller,
    getResellers
};

export default usersService;
