import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';
import authReducer from './authSlice';
import resellersSlice from './resellersSlice';
import ordersSlice from './ordersSlice';
export const store = configureStore({
  reducer: {
    auth: authReducer,
    resellers: resellersSlice,
    vouchers: ordersSlice
  },
});
