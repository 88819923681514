import React, { useEffect, useState } from 'react';
import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addReseller } from '../../store/resellersSlice';

function AddReseller() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const {error} = useSelector((state) => state.resellers)
    const [validated, setValidated] = useState(false);
    const [brand, setBrand] = useState('amazon');
    const [name, setName] = useState();
    const [mobile, setMobile] = useState();
    const [email , setEmail] = useState()
    const [iDImage , setIDImage] = useState(null)
    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {
            event.preventDefault();
            console.log("iDImage")
            console.log(iDImage)
            const formData = new FormData();
            formData.append('iDImage', iDImage)
            formData.append('name', name)
            formData.append('mobileNumber', mobile)
            formData.append('email', email)
            // let userObj = {
            //     name,
            //     mobileNumber: mobile,
            //     email,
            //     iDImage
            // }
            await dispatch(addReseller(formData));
            navigate('/customers')
        }
        setValidated(true);
        
    };
  return (
    <div className='container'>
        <div className='row mx-5'>
            <Card>
                <Card.Title>Add Customer</Card.Title>
                <Card.Body>
                    {
                        error ? 
                        <Row>
                            <p>Problem in adding Customer!</p>
                        </Row>
                        : null
                    }
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Col sm={3}>
                                <Form.Label> Name </Form.Label>
                            </Col>
                            <Col sm={6}>
                                <Form.Control 
                                    type="text" placeholder="name" required 
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Col sm={3}>
                            <Form.Label> Mobile </Form.Label>
                            </Col>
                            <Col sm={6}>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="mobile number"
                                    onChange={(e) => setMobile(e.target.value)}
                                    value={mobile}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Col sm={3}>
                            <Form.Label> Email </Form.Label>
                            </Col>
                            <Col sm={6}>
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="Email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                            <Col sm={3}>
                                <Form.Label> Image </Form.Label>
                            </Col>
                            <Col sm={6}>
                                <Form.Control
                                    type="file"
                                    placeholder="aadhar (any ID Proof)"
                                    onChange={(e) => {
                                        console.log(e.target.files)
                                        setIDImage(e.target.files[0])
                                    }}
                                />
                            </Col>
                        </Form.Group>
                        <Button type="submit">Add Customer</Button>
                    </Form>
                </Card.Body>
            </Card>
            
        </div>
    </div>
  );
}

export default AddReseller;
