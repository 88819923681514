import React, { useEffect, useState } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import { getResellers } from '../../store/resellersSlice';

function Resellers() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { resellers } = useSelector((state) => state.resellers)

    const columns = [
        {
          dataField: "_id",
          text: "ID",
        },
        {
          dataField: "name",
          text: "Customer Name",
        },
        {
          dataField: "mobileNumber",
          text: "Mobile"
        },
        {
          dataField: "email",
          text: "Email"
        },
        {
          dataField: "actions",
          isDummyField: true,
          text: 'Actions',
          formatter: (cell, row, rowIndex) => {
            return (
              <div key={row._id} style={{display: "flex", justifyContent: 'space-evenly'}}>
                  <i style={{color: 'red'}} className="bi bi-trash-fill" onClick={() => {
                     console.log (row);
                     console.log(cell)
                   } } />
                  <i style={{color: 'green'}} className="bi bi-pencil-fill"
                  onClick={() => {
                      navigate('/customers/edit/'+row._id)
                    } } />
              </div>
            )
          }
        }
      
      ];

    const getResellerData = async () => {
      console.log("calling res")
        await dispatch(getResellers())
    }
    useEffect(() => {
      getResellerData()
    }, [])
    
  return (
    <div className='container'>
        <div className='row mb-2' style={{justifyContent: 'end'}}>
            <div className='col-sm-2'>
                <Button onClick={() => navigate('/customers/add')}> Add Customer </Button>
            </div>
        </div>
        <div className='row'>
          <Card style={{ padding: '10px' }}>
            <Card.Title>Customers</Card.Title>
            <Card.Body>
                {
                    (resellers && resellers.length) ?
                    <BootstrapTable
                        bootstrap4
                        keyField="id"
                        data={resellers}
                        columns={columns}
                        pagination={paginationFactory({ sizePerPage: 10, hideSizePerPage: true, onPageChange:(page)=>console.log("DB CALL with page" + page) })}
                    
                    />
                    : null
                }
              
            </Card.Body>
          </Card>
        </div>
    </div>
  );
}

export default Resellers;
