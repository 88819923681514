
import apiRequest from '../utils/api';
import { ADD_ORDER, GET_ORDERS, GET_DETAIL_ORDERS, GET_PRODUCTS } from '../utils/apiRoutes'

const addOrder = async (reqObj, userToken) => {
    return await apiRequest(ADD_ORDER, 'POST', reqObj, userToken);
  };

const getOrders = async (reqObj, userToken) => {
    return await apiRequest(GET_ORDERS, 'POST', reqObj, userToken);
};

const getDetailOrders = async (reqObj, userToken) => {
    return await apiRequest(GET_DETAIL_ORDERS, 'POST', reqObj, userToken);
};

const getProducts = async (reqObj, userToken) => {
    return await apiRequest(GET_PRODUCTS, 'POST', reqObj, userToken);
};

const usersService = {
    addOrder,
    getOrders,
    getDetailOrders,
    getProducts
};

export default usersService;
